#desktop-menu {
    display: block;
}
#mobile-menu {
    display: none;
}

.more-responsive-btn {
    display: none;
}
.contact-type-selection-cards span {
    display: none;
}
@media (max-width: 800px) {


    .contract-listing .c-list .heading-area {
    flex-direction: revert;
}

.contract-listing .c-list .heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.contract-listing .c-list .heading .info {
    flex-direction: column;
    gap: 7px;
}

.contract-listing .c-list .info-area .grid {
    grid-template-columns: 100%;
}

.contract-listing .c-list .info-area .grid .group {
    margin-top: 10px;
}

.contract-listing .c-list>div:last-child {
    flex-direction: column;
    gap: 18px;
}

.content-area.contract-listing {}

.content-area.contract-listing .contract-top-headings {
    flex-direction: column;
    align-items: flex-end;
}

.contract-top-headings a.btn.btn-primary-outline {
    width: 150px;
}

.content-area.contract-listing .contract-top-headings .flex.justify-between.contract-main-action-buttons {
    align-items: flex-end;
    justify-content: flex-end;
}

.contact-type-selection-cards {
    min-height: 38px;
    padding: 10px;
    background: #76bd6d;
    color: #fff;
}

.contact-type-selection-cards span {
    font-size: 0.8rem;
    color: #fff;
}

.contract-build-wizard-contaner .grid.grid-cols-3.gap-4 {
    grid-template-columns: 47% 47%;
}

.contract-build-wizard-contaner {
    justify-content: center;
    align-items: center;
}
    
    .more-responsive-btn {
        display: block;
    }
    #desktop-menu {
        display: none;
    }

    .left-container.flex.flex-col.content-start {
        max-width: 100%;
    }

    #mobile-menu {
        display: flex;
        z-index: 10094;
    }

    .control-left-panel.float-menu {
        max-width: 100%;
        background: #000000ab;
        z-index: 10095;
    }
    
    .mobile-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 55px;
        background: #76bd6d;
        padding: 12px;
        max-width: 100%;
    }
    
    .MuiContainer-root.container-area {
        padding: 60px 0 0 0;
    }
    
    .mobile-header .mobile-burger-btn {
        width: 26px;
        cursor: pointer;
    }
    
    .mobile-header .mobile-burger-btn svg {
        width: 100%;
        height: 100%;
    }
    
    .mobile-header .mobile-burger-btn svg rect {fill: #ffffffba;}
    
    .control-left-panel.float-menu .l-menu {
        max-width: 80%;
    }
    
    .mobile-close-menu {
        position: absolute;
        left: calc(80% + -40px);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #bbdfb6;
        font-weight: 600;
        font-size: 1.4rem;
        cursor: pointer;
    }
    
    .control-left-panel.float-menu .l-ul-menu {
        height: unset;
    }

    .content-area {
        padding: 20px;
    }
    
    .contract-main-action-buttons {
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
    }



    /* Login Page */

    #page-login , #page-password-reset , #page-create-new-password{
        background-size: cover !important;
        height: 100%;
    }
    

    .main-page-group {
        flex-direction: column;
    }

    .main-page-group h1 {
        font-size:2rem;
    }

    .main-page-group h1 + p {
        font-size:1.1rem;
    }

    .forgot-password-link {
        flex-direction: column;
        align-items: flex-start;
    }

    #page-login .left-container {
        padding: 10px;
    }


    /* Register Page */
    #page-register {
        background-size: cover !important;
        height: 100%;
    }

    .register-form > div {
        flex-direction: column;
    }

    .register-form  button[type="submit"] {
        width: unset;
        padding: 10px 25px;
    }

    /* OTP Page */
    #page-otp {
        background-size: cover !important;
        height: 100%;
    }

    .register-form {}

    .register-form > div {
        flex-direction: column;
    }

    .register-form  button[type="submit"] {
        width: unset;
        padding: 10px 25px;
    }

    .notificaiton-top-area {
        top: 55px;
        left: 0;
        display: block;
        text-align: left;
        position: relative;
    }

    .Main-content.admin-dashboard {
        flex-direction: column;
    }
    

    .settings-page {}

    .settings-page .block-group {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .settings-page .block-group .form-area {
        padding: 0;
    }

    .settings-page .block-group .w-1\/4.flex.justify-center {
        width: 100%;
        border-bottom: 1px solid #bbdfb6;
        padding-bottom: 20px;
    }

    .profile-verification {}

    .profile-verification .flex.w-full.gap-5.justify-between.align-center {
        flex-direction: column;
    }

    .profile-verification .profile-verification-actions {
        display: flex;
        justify-content: flex-end;
    }
        


    .add-new-account-css .grid.grid-cols-2 {
        grid-template-columns: 100%;
    }

    .account-account-form {}

    .account-account-form h1 {
        font-size: 1.4rem;
    }

    .account-account-form h1 + a {
        height: 40px;
    }

    .account-account-form form {}

    .account-account-form form .flex.justify-start.gap-3 {
        flex-direction: column;
    }

    .account-account-form form .flex.justify-start.gap-3 .flex.flex-col.gap-2.w-1\/4 {
        width: 100%;
    }

    .account-account-form form .flex.justify-start.gap-3 .flex.flex-col.gap-2.w-1\/2 {
        width: 100%;
    }


    .account-listing-main {}

    .account-listing-main .w-full.contract-display {
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        border-radius: 11px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .account-listing-main .w-full.contract-display .icons-area {
        border-radius: 0px;
    }

    .account-listing-main .w-full.contract-display .action-area {
        border-radius: unset;
    }


    .setting-container label.text-white {
        color: #000 !important;
    }


    .contract-build-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    .contract-build-title > * {
        width: 100%;
    }

    .contract-build-title h1.text-5xl.font-bold.text-primary {
        font-size: 1.5rem;
    }

    .contract-build-title h3.text-2xl.font-bold.text-helper {
        font-size: 1.2rem;
    }

    .wizard-status-high {
        margin-top: 24px;
        grid-template-columns: 50% 50%;
    }

    .wizard-status-high span.text-sm09.text-primary.text-center.font-bold {
        font-size: 0.7rem;
        text-align: center;
    }

    .wizard-status-high .flex.flex-col.gap-1 {
    }

    .wizard-status-high .progress-bar {
        height: 12px;
    }

    .template-card.pt-10.pb-10.ps-14.pe-14 {
        padding: 15px;
    }


    .contract-build-product-detail .grid.grid-cols-2.gap-3 {
        grid-template-columns: 100%;
    }
    .contract-build-delivery .grid.grid-cols-2.w-full.gap-2.mb-2 {
        grid-template-columns: 100%;
    }

    .template-card.pt-10.pb-10.ps-14.pe-14 .grid.grid-cols-2.gap-4.items-center.justify-between.p-14 {
        grid-template-columns: 100%;
    }

    .content-area.contract-listing {}

    .content-area.contract-listing .contract-display.flex.mb-5 {
        flex-direction: column;
        text-align: left;
    }

    .content-area.contract-listing .contract-display.flex.mb-5 .icons-area.bg-primary-light.flex.items-center.p-3.rounded-l-lg {
        justify-content: center;
        border-radius: 10px 10px 0px 0px;
    }

    .content-area.contract-listing .contract-display .action-area.flex.flex-col.justify-between.rounded-r-lg.bg-primary.p-10.pb-3.pt-3 {
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        gap: 15px;
    }

    div#contract_view {}

    div#contract_view .p-14.pt-0 {
        padding: 10px;
    }

    div#contract_view .fixed-contract-page-footer {
        left: 0;
    }

    .fixed-contract-page-footer {
        left: 0;
    }

    .handoff-checklist .title-group.grid.grid-cols-2.gap-10 {
        grid-template-columns: 100%;
    }

    .handoff-checklist .grid.grid-cols-2.gap-10 {
        grid-template-columns: 100%;
    }

    

    .handoff-checklist .grid.grid-cols-2.gap-10 {
        padding: 0px;
    }


    .contract-status-view {
        position: absolute;
        bottom: 81px;
        left: 0;
        right: 0;
        height: 48px;
        background: white;
        display: flex;
        justify-content: center;
        box-shadow: 0px -7px 10px 0px #00000024;
    }

    .contract-biding {
        padding-bottom: 100px;
    }

    .cotract-view-action-buttons {}

    .cotract-view-action-buttons .btn-more-btn-group {
        position: absolute;
        flex-direction: column;
        bottom: 72px;
        background: #fff;
        padding: 20px;
        width: 210px;
        right: 13px;
        border: 1px solid #ddd;
        border-radius: 10px;
        z-index: 10093;
        display: none;
    }

    .cotract-view-action-buttons .btn-more-btn-group.active {
        display: flex;
    }

    .cotract-view-action-buttons .btn-default-group {
        align-items: center;
    }

    .cotract-view-action-buttons .btn-default-group .more-responsive-btn {
        border: 1px solid #76bd6d;
        padding: 15px 8px;
        border-radius: 5px;
        background: #fff;
        color: #76bd6d;
    }

    .cotract-view-action-buttons .btn-default-group .more-responsive-btn svg path {
        fill: green;
    }

    .cotract-view-action-buttons .btn-default-group .more-responsive-btn svg {
        width: 25px;
    }

    #page-password-reset form.w-full.rounded-md.bg-primary-light.p-10 {
        padding: 20px 10px;
    }
    
    #page-password-reset form .w-2\/3 {
        width: 100%;
    }
    
    #page-password-reset form button {
        width: unset;
    }

    #page-create-new-password form.w-full.rounded-md.bg-primary-light.p-10 {
        padding: 20px 10px;
    }

    #page-create-new-password .w-2\/3.flex.flex-col.gap-3 {
        width: 100%;
    }


    .fw-auth-area {
        position: relative;
        left: unset;
        right: unset;
        width: 100%;
        bottom: unset;
        top: unset;
        display: flex;
        flex-direction: column;
    }
    
    .fw-auth-area .div-left-area {
        width: 100%;
        top: 0;
        bottom: unset;
        position: relative;
    }
    
    .fw-auth-area .div-right-area {
        position: relative;
        left: 0;
        height: 100%;
    }
}
