@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#react-root {
  height: 100%;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
  background: #f6f6f6;
}

img {
  display: inline-block;
  max-width: 100%;
}

/* .Main-content {
  min-height: calc(100vh - 100px);
} */

/* --------------------------------------------------------------------------------
Container
-------------------------------------------------------------------------------- */

.container {
  padding: 20px;
}

.card:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* --------------------------------------------------------------------------------
Button
-------------------------------------------------------------------------------- */

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-color: #000000;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  background-color: #76bd6d;
  color: #f8f8f8;
  font-size: 1.1rem;
  outline: 0;
  cursor: pointer;
}

.btn_sizeMin {
  min-height: 50px;
  min-width: 100px;
}

.btn_sizeSm {
  padding: 10px 15px;
  font-size: 0.8rem;
}

.btn_sizeFull {
  display: block;
  width: 100%;
}

.btn:hover {
  background-color: #54d57f;
  text-decoration: none;
}

/* This line makes the "Register" and "Login" buttons look like links */
.btn_asLink {
  padding: 0;
  border-width: 0;
  border-bottom: 1px solid #444;
  border-radius: 0;
  background: none;
  font: inherit;
  color: #444;
}

.btn_asLink:hover {
  background-color: transparent;
  border-color: #76bd6d;
  color: #76bd6d;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

.alert-success {
  border: 2px solid #1e9d7f;
  color: #00765b;
}

/* --------------------------------------------------------------------------------
Form Treatment
-------------------------------------------------------------------------------- */

.formPanel {
  box-sizing: border-box;
  width: 400px;
  max-width: 100%;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: #efefef;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
}

input {
  margin: 5px;
  margin-bottom: 10px;
}

.root-page-bg-style {
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
}

.spliter-bg:after {
  background: #76bd6d;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  content: " ";
  z-index: -1;
  width: calc(100% / 2);
}

.ul-disc li {
  list-style: disc;
}

.ul-disc {
  padding-left: 20px;
}

.regsiter-form {
  max-width: 800px;
}

ul.pass-instruction {
  padding-left: 20px;
}

.spliter-bg-how-its-works::after {
  background: #d3c0ff;
}

.h-100-p {
  height: 100%;
}

.tdo-modal-full {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  background: #fff;
  padding: 20px;
}

.tdo-modal-full .tdo-modal-content {
  position: absolute;
  top: 64px;
  left: 20px;
  right: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  bottom: 15px;
}

.tdo-modal-full .tdo-modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
}

.tdo-modal-full .tdo-modal-content::-webkit-scrollbar {
  width: 0.5em;
}
.tdo-modal-full .tdo-modal-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px #ab87ff;
  border-radius: 30px;
}

.tdo-modal-full .tdo-modal-content::-webkit-scrollbar-thumb {
  background-color: #76bd6d;
  outline: 1px solid #76bd6d;
  border-radius: 8px;
}

.tdo-modal-content-scroll {
  padding: 0 20px 0 0;
}

.no-scroll {
  overflow: hidden !important;
}

/* Loader */
.load-container {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 25px;
  box-sizing: border-box;
  overflow: hidden;
  letter-spacing: normal;
}

/*************
 * ローダー1
 ************/
.one .loader,
.one .loader:before,
.one .loader:after {
  background-color: #fff; /* アイコンの色 */
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 3em;
}
.one .loader:before,
.one .loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.one .loader:before {
  left: -1.5em;
}
.one .loader {
  font-size: 3px;
  text-indent: -9999em;
  margin: 2.3em auto;
  position: relative;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.one .loader:after {
  left: 1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #fff; /* アイコンの色 */
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #fff; /* アイコンの色 */
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #fff; /* アイコンの色 */
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em #fff; /* アイコンの色 */
    height: 5em;
  }
}

/* Loader */

.MuiContainer-root.container-area {
  padding-left: 320px;
}

.image-upload-button {
  position: relative;
}

.image-upload-button input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}

.flex-direction-column {
  flex-direction: column !important;
}

/* Admin Left Panel Start */
.control-left-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: lightgrey;
  max-width: 320px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

.control-left-panel .logo-admin {
  background: white;
  padding: 30px 10px;
  justify-content: center;
  display: flex;
}

.control-left-panel .l-menu {
  height: 100%;
  background: white;
}

.control-left-panel .l-menu .quick-profile-view {
  padding: 14px 20px;
  display: flex;
  justify-content: center;
}

.control-left-panel .l-menu .quick-profile-view .avatar {
  width: 80px;
  height: 80px;
  display: flex;
  overflow: hidden;
  border-radius: 80px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  min-height: 80px;
  box-shadow: 1px 3px 6px 1px #00000042;
}

.control-left-panel .l-menu .quick-profile-view .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.control-left-panel .l-menu .quick-profile-view .basic-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.control-left-panel .l-menu .quick-profile-view .basic-info h1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.control-left-panel .l-menu .quick-profile-view .basic-info p {
  font-weight: bold;
}

.control-left-panel .l-menu .l-ul-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100% - 234px);
}

.control-left-panel .l-menu .l-ul-menu .primary-menu {
  width: 100%;
  padding-left: 10px;
  padding-top: 15px;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul {
  gap: 10px;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li {
  /*  padding: 15px 0 15px 10px; */
  border-radius: 4px 0 0px 4px;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li .menu-cover {
  display: flex;
  gap: 15px;
  height: 100%;
  align-items: center;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li.active,
.control-left-panel .l-menu .l-ul-menu .primary-menu ul li:hover {
  background: #76bd6d;
  color: #fff;
  cursor: pointer;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li .menu-cover span {
  font-size: 0.9rem;
}

.control-left-panel .l-menu .l-ul-menu .footer-menu {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.control-left-panel .l-menu .l-ul-menu .footer-menu ul {
  padding-left: 10px;
}

.control-left-panel .l-menu .l-ul-menu .footer-menu ul li {
  padding: 15px 0 15px 10px;
}

.control-left-panel .l-menu .l-ul-menu .footer-menu ul li .menu-cover-plain {
  display: flex;
  gap: 15px;
  height: 100%;
  align-items: center;
}

.control-left-panel
  .l-menu
  .l-ul-menu
  .primary-menu
  ul
  li.active
  .menu-cover
  .icon
  path,
.control-left-panel
  .l-menu
  .l-ul-menu
  .primary-menu
  ul
  li:hover
  .menu-cover
  .icon
  path {
  fill: #fff;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li .menu-cover a {
  display: flex;
  gap: 15px;
  align-items: center;
  height: 100%;
  width: 100%;
}

.control-left-panel .l-menu .l-ul-menu .primary-menu ul li a {
  padding: 15px 0 15px 10px;
  display: flex;
  width: 100%;
}

.control-left-panel
  .l-menu
  .l-ul-menu
  .primary-menu
  ul
  li
  .menu-cover
  .icon
  path {
  fill: #000;
}

/* Admin Left Panel End */

.Main-content.admin-dashboard {
  display: flex;
}

/* Buttons */
.container-area {
  width: calc(100% - 200px);
  float: left;
}

.Main-content.admin-dashboard {
  display: flex;
  background-color: #f6f6f6;
  height: unset;
}

.content-area {
  padding: 40px;
  height: 100%;
}

.btn {
  border: 1px solid;
  padding: 12px 24px;
  font-size: 0.9rem;
  font-weight: 600;
}

.btn-primary {
  background: #76bd6d;
}

.btn-primary-outline {
  background: #fff;
  color: #76bd6d;
}

.btn-primary-outline:hover {
  background: #bbdfb6;
  color: #fff;
}

.btn-primary:hover {
  background: #bbdfb6;
}

.btn-primary-light.active {
  background: #76bd6d;
  color: #fff;
}

.btn-primary-light:hover {
  background: #bbdfb6;
}

.btn-primary-light {
  background: #bbdfb6;
  color: #76bd6d;
  border-color: #bbdfb6;
}

.btn-primary-light:hover {
  background: #76bd6d;
  color: #fff;
}
.btn-sm {
  padding: 10px 18px;
  font-size: 0.7rem;
}
/* Buttons */

.contract-display .info-area {
  border: 1px solid #76bd6d;
  border-left: unset;
  border-right: unset;
}

.strenght-style {
  width: 24px;
  height: 24px;
  border-radius: 30px;
}
.profile-strength {
  align-items: center;
  margin-top: 0;
}
.strenght-style-clear {
  background: #76bd6d94;
}

.strenght-style-inreview {
    background: #76bd6d;
    width: 30px;
    height: 30px;
}

.strenght-style-unverified {
  border: 2px dashed #bbdfb6;
  background: #f9f9f9;
}

.container-area {
  padding-left: 264px;
  right: 0;
  width: 100%;
}

/* Tabs */
.control-left-panel
  .l-menu
  .l-ul-menu
  .primary-menu
  ul
  li
  .menu-cover
  .icon
  path {
  fill: #000;
}

.tabs {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.tabs .tab-header {
  display: flex;
  background: #fff;
}

.tabs .tab-header ul {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.tabs .tab-header ul li {
  border: 1px solid #d9d9d9;
  width: 100%;
  text-align: center;
  /* border-radius: 8px 8px 0 0; */
  font-weight: 400;
  background: white;
}
.tabs .tab-header ul li:first-child {
  border-radius: 8px 0 0 0;
}
.tabs .tab-header ul li:last-child {
  border-radius: 0 8px 0 0;
}
.tabs .tab-container {
  box-shadow: 0px 4px 9px 1px #00000014;
}

.tabs .tab-header ul li.acitve,
.tabs .tab-header ul li:hover {
  background: #76bd6d;
  color: #fff;
  cursor: pointer;
}

.tabs .tab-container .tab-content {
  padding: 30px 40px;
}
/* Tabs */

/* AVATAR PP */
.avatar-pp-editor {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.avatar-pp-editor .profile-avatar {
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 3px 2px #ddd;
}

.avatar-pp-editor img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* AVATAR PP */

/* Edit Prfoile / General Tab */
.form-area {
  width: 100%;
}

.form-area {
  width: 100%;
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  background: #fafafa;
}

.settings-input label {
  color: #000 !important;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 6px;
  display: block;
}

.settings-input .ow-input-group input {
  border: 2px solid #bbdfb6;
  padding: 10px;
}
/* Edit Prfoile / General Tab */

.block-action-ui[disabled] {
  position: relative;
  overflow: hidden;
}
.block-action-ui[disabled]:after {
  content: "Processing...";
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background: #424242b5;
  border-radius: 10px;
  color: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  pointer-events: none;
  font-style: italic;
}

.progress-bar {
  width: 100%;
  background: #adacb0;
  height: 22px;
  border-radius: 20px;
  box-shadow: inset 0px 0 7px 2px #00000045;
  position: relative;
  overflow: hidden;
}

.progress-bar span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(1deg, #76bd6d, #93eb88);
  width: 0;
  border-radius: 20px;
}

.template-card {
  width: 100%;
  box-shadow: 0px 4px 8px 0px #ddd;
  border-radius: 10px;
}

.contract-build-wizard-contaner {
  width: 100%;
  background-color: #fff;
}
.photo-uploader-box {
  min-height: 200px;
  border: 1px solid #76bd6d;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.photo-uploader-box .before-upload {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-uploader-box .before-upload .file-input-blocker {
  position: relative;
}

.photo-uploader-box .before-upload .file-input-blocker input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}

.photo-uploader-box .after-upload {
  max-height: 200px;
  overflow: hidden;
}

.photo-uploader-box .after-upload img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photo-uploader-box .after-upload span.photo-remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: #bbdfb6;
  border-radius: 0px 0px 0px 10px;
  font-size: 0.8rem;
  padding: 2px 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.photo-uploader-box .after-upload span.photo-remove-button:hover {
  background: #76bd6d;
}

.react-datepicker__input-container input {
  width: 200px;
  border: 1px solid rgb(177 177 177 / 87%);
  border-radius: 2px;
  padding: 5px 10px;
  margin: 0;
  color: #000;
}

.react-datepicker__tab-loop {
  z-index: 5;
}

.contract-display .info-area {
  width: 100%;
}

ul.ul-number {
  padding-left: 13px;
  margin: 20px 0;
}

ul.ul-number li {
  list-style: num;
  padding-left: 20px;
}

.inv-con-footer-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #ddd;
  padding: 10px;
}

.contract-biding {
  padding-bottom: 65px;
}

.tabs .tab-header ul li a {
  padding: 10px;
  display: block;
}

.upload-image-btn {
  position: relative;
  cursor: pointer;
}

.file-input-blocker {
  position: relative;
}
.upload-image-btn input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  margin: 0px;
}

.upload-image-btn {
  position: relative;
}

.upload-image-btn input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}

.photo-uploader-box ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.photo-uploader-box ul li {
  display: flex;
  align-items: center;
}

.photo-uploader-box ul li img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.photo-uploader-box ul li .image-preview {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
}

.photo-uploader-box ul li span {
  background: red;
  color: #fff;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 5px;
  margin-left: 9px;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid red;
}

.photo-uploader-box ul li span:hover {
  border-color: red;
  color: red;
  background: #fff;
}

.app-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #0000008f;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-modal-container {
  background: #fff;
  border-radius: 10px;
  max-width: 800px;
}

.app-modal-container .app-modal-header {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-modal-container .app-modal-header h1 {
  font-weight: 600;
  font-size: 1.2rem;
}

.app-modal-container .app-modal-header .close-btn {
  cursor: pointer;
}

.app-container-content {
  padding: 10px;
}

span.contract_status {
  text-transform: capitalize;
}

.contract_status {
  background: green;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 0.7rem;
}

span.contract_status {
  text-transform: capitalize;
}

.contract_status {
  background: green;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 0.7rem;
}

span.icon-archive {
  font-size: 1.5rem;
  color: #76bd6d;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-capitalize {
  text-transform: capitalize !important;
}
hr {
  border-color: #bbdfb6;
}

.user_login {
  right: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-left: calc(264px + 40px);
}

.user_login .inv-con-footer-buttons {
  left: 320px;
}

.label-bold span.MuiFormControlLabel-label {
  font-weight: 600;
}

.handoff-photo-uploader {
  align-items: center;
  display: flex;
}

.handoff-photo-uploader .input-note-data {
  width: 100%;
}

.handoff-photo-uploader .after-image-upload {
  width: 55px;
  position: relative;
}

.handoff-photo-uploader .after-image-upload .remove {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  background: #ce0007;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.WizardContractSubmit .photo_added {
  width: 100px;
  height: 100px;
}

.WizardContractSubmit .photo_added img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fixed-contract-page-footer {
  position: fixed;
  bottom: 0px;
  left: 321px;
  right: 0;
  height: 80px;
  padding: 16px 20px;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #76bd6d78;
}

.is_archived {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: hue-rotate(154deg);
}

.is_archived img {
  width: 500px;
}
.is_rejected {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is_rejected img {
  width: 500px;
}

.contract-biding {
  position: relative;
}
.btn svg path {
  fill: #76bd6d;
}

.btn:hover svg path {
  fill: #fff;
}

.contract-images {
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.contract-images .c-image {
  width: 100%;
  height: 200px;
  background: rgb(216, 216, 216);
  border-radius: 10px;
  overflow: hidden;
}

.contract-images .c-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.rdt input.form-control {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 5px 10px;
}

/* Auth Box */
.fw-auth-area {
  position: fixed;
  left: 0;
  right: 0;
  bottom: auto;
  top: 0;
}

.fw-auth-area .div-left-area {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 40%;
  background: #bbdfb6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
}

.fw-auth-area .div-right-area {
  left: 40%;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: -1 ;
}

.fw-auth-area .div-left-area > div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fw-auth-area .div-left-area .logo {
  min-width: 300px;
}

.fw-auth-area .div-left-area .logo img {
  width: 300px;
  height: 48px;
}
.contract-expired {
  text-align: center;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 40px;
  font-style: italic;
  color: black;
}
/* Auth Box */

.photo-avatar {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 250px;
}

.handoff-photo-uploader .after-image-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-button svg {
  width: 100%;
  height: 100%;
}

.image-upload-button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-upload-button {
  width: 100%;
  margin: 0 2rem;
}

.handoff-photo-uploader .after-image-upload {
  width: 100%;
  height: 100%;
}

ul.verification_handoff_list li {
  display: flex;
  align-items: center;
  gap: 10px;
}

ul.verification_handoff_list li svg {
  width: 20px;
}

.handoff-checklist {
  margin-bottom: 40px;
}

.handoff-checklist span {
}

.handoff-checklist .title-group span {
  padding: 4px;
  background: #bbdfb6;
  border: 1px solid #76bd6d;
  justify-content: center;
  display: flex;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 2px;
  color: #fff;
}

.handoff-checklist .handoff-single {
  padding: 20px;
  border: 1px solid #76bd6d;
  border-top: unset;
  height: 100%;
}

.handoff-checklist .handoff-single .photo_added {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}

.handoff-checklist .handoff-single .photo_added img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.handoff-checklist .handoff-single .checklist {
}

.handoff-checklist .handoff-single .checklist svg {
  width: 24px;
}

.handoff-checklist .handoff-single h3 {
  margin-top: 4px;
}

.handoff-checklist .handoff-single .verification_handoff_list {
}

.handoff-checklist .handoff-single .verification_handoff_list svg {
  width: 15px;
}

.notifications-board {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
}

.notifications-board .single-notifcation {
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  min-height: 75px;
  border: 1px solid #bbdfb6;
}

.notifications-board .single-notifcation .left-bar {
  width: 20px;
  background: #bbdfb6;
}

.notifications-board .single-notifcation .content-bar {
  padding: 5px 10px;
  width: 100%;
}

.notifications-board .single-notifcation .content-bar h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #434343;
  margin-bottom: 1px;
}

.notifications-board .single-notifcation .content-bar .message {
  color: #9d9d9d;
}

.notifications-board .single-notifcation:hover {
  background: #bbdfb61c;
}

.notifications-board .single-notifcation .content-bar .timestamp {
  text-align: right;
  width: 100%;
  font-size: 0.8rem;
  font-style: italic;
  color: #838383;
  margin: 10px 0 2px;
}

.required-strik-avoid span.MuiFormControlLabel-asterisk {
  display: none !important;
}

.is_expired {
  position: fixed;
  right: 0;
  padding: 6px 10px;
  background: #ff2800;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  top: 100px;
}

.tabs .tab-header ul li.disabled-tab-btn {
  background: grey;
  color: #bdbdbd;
  pointer-events: none;
}

.file-upload-custom-btn {
  width: 100%;
  border-radius: 5px;
  background: #216ba5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  padding: 6px 10px;
  border: 1px solid #216ba5;
  transition: all 0.2s;
  cursor: pointer;
}

.file-upload-custom-btn input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload-custom-btn:hover {
  background: transparent;
  color: #216ba5;
}

.is_archived_tag {
  position: fixed;
  right: 0;
  padding: 6px 10px;
  background: #ff2800;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  top: 60px;
}

.is_canceled_tag {
  position: fixed;
  right: 0;
  padding: 6px 10px;
  background: #ff2800;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  top: 60px;
}

.contact-type-selection-icons {
  position: relative;
  overflow: hidden;
}

.contact-type-selection-icons span.coming_soon_tag {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  justify-content: center;
  color: #fff;
  align-items: center;
  display: flex;
  background: transparent;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.5rem;
  font-style: italic;
}

/* Loader */
.main-loader {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  background: #327c4a85;
  justify-content: center;
  display: flex;
  align-items: center;
}

.main-loader .load-container.one {
  width: 100px;
  height: 70px;
}

.main-loader .load-container.one .loader {
  font-size: 10px;
  justify-content: center;
}

.link {
  text-decoration: underline;
  color: #00765b;
  font-weight: 500;
  cursor: pointer;
}

.notificaiton-top-area {
  position: fixed;
  top: 0;
  left: 264px;
  right: 0;
  background: #c90909;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.text-white {
  color: #fff;
}

.address_auto_complete {
}

.address_auto_complete label {
  overflow: hidden;
  visibility: collapse;
  position: relative;
  min-height: 45px;
}

.address_auto_complete label input {
  border: 1px solid #c3c3c3;
  padding: 6px 10px;
  border-radius: 5px;
  width: 100% !important;
  max-width: 100%;
  visibility: initial;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.location-search-input {
  border: 1px solid #c3c3c3;
  padding: 6px 10px;
  border-radius: 5px;
  width: 100% !important;
  max-width: 100%;
  visibility: initial;
  margin: 0;
}

.address_auto_complete label {
  display: none;
}

.address_auto_complete label:first-child {
  display: block;
}

.address_auto_complete button {
  display: none;
}

.autocomplete-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  border: 1px solid #ddd;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 100;
}

.autocomplete-dropdown-container > * {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.address_auto_complete {
  position: relative;
}

#disabled_click_on_flag .MuiInputAdornment-positionStart {
  pointer-events: none;
}

div#navbar-default.active {
  display: block;
  position: absolute;
  top: 76px;
  right: 0;
  left: 0;
  background: #57a94d;
}

div#navbar-default.active ul {
  margin: 0;
  border: unset;
  text-align: right;
  padding: 10px 20px;
}
.left-container.flex.flex-col.content-start {
  max-width: 50%;
}

.handoff-checklist .handoff-single {
  flex-direction: column;
  padding: 0;
  margin-bottom: 7px;
}

.handoff-checklist .handoff-single .flex.flex-col.w-full.gap-2.mb-2 {
  padding: 10px;
}

.handoff-checklist .handoff-single span.title {
  background: #76bd6d;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}

.bd {
  border: 1px solid red;
}


.matrialui-local {}

.matrialui-local label.text-white {
    color: rgb(118 189 109 / var(--tw-text-opacity));
}

.matrialui-local label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    /* display: none; */
    color: #8a8a8a;
    margin-top: 0px;
    display: none;
}

.matrialui-local .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background: #fff;
    border: none;
    min-height: 50px;
    height: 50px;
    display: flex;
}

.matrialui-local {}

.matrialui-local fieldset {
    border: none;
}

.matrialui-local .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    height: 100%;
}

.matrialui-local label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl[data-shrink="false"] {
    margin-top: 5px;
    display: block;
}


.concent-modal-box {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000001c;
}

.concent-modal-box {}

.concent-modal-box .concent-modal-box-area {
  max-width: 650px;
  max-height: 400px;
  overflow: hidden;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.concent-modal-box .concent-modal-box-area .aggrement-area {
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
}


.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.concent-modal-box {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000001c;
}

.concent-modal-box {}

.concent-modal-box .concent-modal-box-area {
  max-width: 850px;
  max-height: 600px;
  overflow: hidden;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.concent-modal-box .concent-modal-box-area .aggrement-area {
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
}

.div {}

.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.concent-modal-box .concent-modal-box-area {
  position: relative;
  overflow: unset;
}

.concent-modal-box .concent-modal-box-area .close-modal {
  position: absolute;
  right: -15px;
  top: -15px;
  background: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.concent-modal-box .concent-modal-box-area .close-modal svg path {
  height: 20px;
  fill: gray;
}

.concent-modal-box .concent-modal-box-area .close-modal {}

.concent-modal-box .concent-modal-box-area .close-modal:hover svg path {
  fill: #fff;
}

.concent-modal-box .concent-modal-box-area .close-modal:hover {
  background: #bbdfb6;
}

.contract-build-title.flex.direction-column.mb-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-type-selection-cards {
  min-height: 200px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 0px #efefef;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

.contact-type-selection-cards span {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333333;
}
.aggrement-area p {
  margin-bottom: 20px;
}

.aggrement-area strong {margin-bottom: 10px;}


.static-page.content-page {
  padding: 20px 0;
}

.static-page.content-page h4 {
  font-weight: 700;
  margin-bottom: 10px;
  color: #2c0962;
  font-size: 1.2rem;
}

.static-page.content-page p {
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: #474747;
}

.static-page.content-page a {
  color: #76bd6d;
  font-weight: 600;
}

.static-page.content-page {
  padding: 20px 0;
}

.static-page.content-page h4 {
  font-weight: 700;
  margin-bottom: 10px;
  color: #2c0962;
  font-size: 1.2rem;
}

.static-page.content-page p {
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: #474747;
}

.static-page.content-page a {
  color: #76bd6d;
  font-weight: 600;
}

.contract-top-headings {}

.contract-top-headings .flex.gap-5 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #595959;
  align-items: center;
}

.contract-top-headings .flex.gap-5 button.link-button.active, .contract-top-headings .flex.gap-5 button.link-button:hover {
  border-bottom-color: #76bd6d;
  color: #76bd6d;
}

button.link-button {}

.contract-top-headings .flex.gap-5 button.link-button {
  border-bottom: 2px solid transparent;
  color: #818181;
  height: 30px;
}

.contract-top-headings a.btn.btn-primary-outline {
  background: #76bd6d;
  color: #fff;
  font-weight: 400;
  display: flex;
  gap: 10px;
}

.contract-listing {}

.contract-listing {}

.contract-listing .c-list {}

.contract-listing .c-list > div {
  border-bottom: 1px solid #ddd;
}

.contract-listing .c-list {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
}

.contract-listing .c-list .heading {
  display: flex;
  align-items: center;
  gap: 40px;
}

.contract-listing .c-list .heading h4 {
  font-size: 1.4rem;
}

.contract-listing .c-list h4, .contract-listing .c-list h5, .contract-listing .c-list h6 {
  color: #181818;
}

.contract-listing .c-list {
  color: #5d5d5d;
}

.contract-listing .c-list .heading {}

.contract-listing .c-list .heading .info {
  display: flex;
  gap: 40px;
}

.contract-listing .c-list .heading .actions {}

.flex.gap-3.justify-between.heading-area {
}

.contract-listing .c-list .heading-area {padding-bottom: 14px;}

.contract-listing .c-list .heading-area .actions {}

  .contract-listing .c-list .heading-area .actions .tag {
  background: #76bd6d;
  color: #fff;
  padding: 6px 20px;
  border-radius: 40px;
  font-size: 0.8rem;
}

.contract-listing .c-list .info-area {
  width: 100%;
}

.contract-listing .c-list .info-area .grid {
  grid-template-columns: 33.33% 33.33% 33.33%;
  min-width: 100%;
}


.contract-listing .c-list > div:last-child {
  border-bottom: 0px;
}

.contract-listing .c-list .info-area .grid .group {
  line-height: 20px;
  font-size: 0.9rem;
}

.contract-listing .c-list .info-area  h6 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 0.95rem;
}

.contract-listing .c-list .info-area .grid .group p {
  font-size: 0.86rem;
}

.contract-listing .c-list .info-area {
  padding: 10px 0 10px;
}

.contract-listing .c-list .info-area .flex {
  width: 100%;
  min-width: 100%;
}

.grid {}

.contract-listing .c-list .info-area  b {
  font-weight: 600;
}

.contract-listing .c-list .info-area span {
  font-size: 0.86rem;
}
.footer-actions {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-actions .open-contract-btn {
}

.footer-actions .open-contract-btn a {
  color: #76bd6d;
  align-items: center;
  display: flex;
}

.contract-listing .c-list .profile-strength {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.footer-actions .open-contract-btn a:hover {
  text-decoration: underline;
}

.contract-listing .c-list .profile-strength span.strenght-style.strenght-style-clear {
  background: #76bd6d94;
}

.contract-listing .c-list .profile-strength span.strenght-style.strenght-style-inreview {
  background: #76bd6d;
  width: 30px;
  height: 30px;
  outline: unset;
}

.strenght-style-unverified {
  border: 2px dashed #bbdfb6;
  background: #76bd6d40;
}

.contract-listing .c-list .footer-area-c {
  padding: 15px 0 0;
}

.fw-auth-area .div-left-area .overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #76bd6d24;
  z-index: -1;
}
.fw-auth-area .div-left-area > div  form {
  background: transparent;
}

.fw-auth-area .div-left-area > div  form li.text-white.text-sm {
  color: #5b5b5b;
}

.fw-auth-area .div-left-area > div form small.text-white.text-sm {
  color: #000;
}

.fw-auth-area .div-left-area.reigser-long {
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  padding: 40px 0;
}

.content-page-static {}

.content-page-static h3 {
    margin-bottom: 10px;
}

.content-page-static p {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.content-page-static {
    display: flex;
    flex-direction: column;
}

.content-page-static strong {
    margin-bottom: 15px;
}

.content-page-static {}

.content-page-static h3 {
    margin-bottom: 10px;
}

.content-page-static p {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.content-page-static {
    display: flex;
    flex-direction: column;
}

.content-page-static strong {
    margin-bottom: 15px;
}

.table-dis {}

.table-dis h4 {
    padding: 10px;
    background: #dbdbdb;
    font-weight: 600;
    border: 1px solid #ddd;
}

.table-dis {
}

.table-dis table {
}

.table-dis table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.table-dis table th {
    border: 1px solid #ddd;
    padding: 5px;
}

.table-dis table tr.full-row {}

.table-dis table tr.full-row td {
    background: #c3c3c3;
    font-weight: 400;
}