.hiw-ul {}

.hiw-ul li {
    position: relative;
}

.hiw-ul li span.round-number {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 5px;
    z-index: 2;
}

.hiw-ul li .flex.flex-col {
    padding-left: 70px;
}

.hiw-ul li:after {
    content: " ";
    bottom: 0;
    top: 0px;
    background-color: #6622cc;
    width: 7px;
    position: absolute;
    left: 14px;
    z-index: 1;
}

.hiw-ul li:first-child:after {
    top: 6px;
}

.hiw-ul li:last-child:after {
    bottom: unset;
    height: 27px;
}