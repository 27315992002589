.ow-input-group {
    display: flex;
    align-items: center;
    position: relative;
}

.ow-input-group span {
    position: absolute;
    left: 30px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

/* .ow-input-group span svg  , .ow-input-group span svg path{
    fill: #76bd6d;
} */

.ow-input-group input {
    width: 100%;
    margin: 0;
    padding: 15px;
    padding-left: 65px;
    padding-right: 30px;
}

.ow-input-group input.nromal-padding {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.ow-input-group input:focus {
    outline-color: #76bd6d;
}