.nav {
  /* flex box layout */
  /* display: flex;
  justify-content: space-evenly; */
  /* align-items: center; */

  margin: 0 0 30px;
  background-color: #2b2b2b;
  overflow: hidden;
}

.nav a {
  text-decoration: none;
}

.nav-title {
  font-size: 24px;
  font-weight: 700;
  color: #f2f2f2;
  padding-left: 10px;
  margin: 0;
}

.navLink {
  display: inline-block;
  color: #f2f2f2;
  background-color: #2b2b2b;
  text-align: center;
  padding: 30px 10px;
  text-decoration: none;
  font-size: 15px;

  /* these last two make the logout button in
  the nav bar look like another link */
  border: none;
  cursor: pointer;
  outline: 0;
}

.navLink:hover {
  color: #4FC576;
  background-color: #313131;
}
